import {Button, Card, Input, Table} from "antd";
import {ArrowRightOutlined, SearchOutlined} from "@ant-design/icons";
import {ReactElement, useEffect, useState} from "react";
import {get} from '../core/api'

interface ApiTableProps {
    id: string,
    url: string,
    title?: ReactElement<any, any>,
    searchable?: boolean,
    actions?: any,
    tableProps?: any,
}

function get_sorter(type: string, dataIndex: string) {
    switch (type) {
        case "numerical":
            return ((a: any, b: any) => a[dataIndex] - b[dataIndex])
        case"alphabetical":
            return ((a: any, b: any) => String(a[dataIndex]).localeCompare(String(b[dataIndex])))
    }
}

function get_table(id: string) {
    if (!window.localStorage.tables) {
        window.localStorage.tables = JSON.stringify({});
    }
    let tables = JSON.parse(window.localStorage.tables);
    if (!tables.hasOwnProperty(id)) {
        tables[id] = {
            "sorter": [undefined, undefined],
            "filters": []
        };
    }
    return tables[id];
}

function set_table(id: string, table: {}) {
    let tables = JSON.parse(window.localStorage.tables);
    tables[id] = table;
    window.localStorage.tables = JSON.stringify(tables);
}

export default function ApiTable(props: ApiTableProps) {
    const [loading, setLoading] = useState(true);
    const [apiData, setApiData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);


    useEffect(() => {
        get(props.url)
            .done((resp) => {
                    var apiData = resp.data
                    setApiData(apiData);
                    setFilteredData(apiData);
                    let colums = resp.columns
                    for (let colum of colums) {
                        if (colum.sorter) {
                            colum.sorter = get_sorter(colum.sorter, colum.dataIndex)
                        }
                        colum.onFilter = (value: any, record: any) => record[colum.dataIndex] === value;
                    }
                    if (props.actions) {
                        colums = [...colums,
                            {
                                title: "",
                                render: props.actions,
                                width: 100
                            }]
                    }
                    loadFilters(colums);
                    setLoading(false);
                }
            ).fail((resp) => setLoading(false))
    }, [props.url, props.actions, props.id])

    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        let table = get_table(props.id);
        if (extra['action'] === "sort") {
            table['sorter'] = [sorter['field'], sorter['order']];
            set_table(props.id, table);
            loadFilters(columns);
        } else if (extra['action'] === "filter") {
            table['filters'] = filters;
            set_table(props.id, table);
            loadFilters(columns);
        }
    }

    function loadFilters(cols: any) {
        let table = get_table(props.id);
        for (let colum of cols) {
            // @ts-ignore
            if (colum.sorter && table['sorter'][0] === colum.dataIndex) {
                // @ts-ignore
                colum.sortOrder = table['sorter'][1];
            }
            // @ts-ignore
            if (table['filters'].hasOwnProperty(colum.dataIndex)) {
                // @ts-ignore
                colum.filteredValue = table['filters'][colum.dataIndex];
            }
        }
        setColumns(cols);
    }

    return (
        <Card
            headStyle={{backgroundColor: 'rgb(250, 250, 250)'}}
            bodyStyle={{padding: 0}}
            title={props.title}
            extra={
                props.searchable ? (
                    <Input
                        placeholder="Tabelle durchsuchen..."
                        suffix={<SearchOutlined/>}
                        allowClear
                        onChange={
                            (e) => {
                                let searchVal = e.target.value
                                if (searchVal.length === 0) {
                                    // emtpy search -> fill original data
                                    setFilteredData(apiData);
                                } else {
                                    // filter original data by search string
                                    setFilteredData(apiData.filter((e) => {
                                            for (let key in e) { // loop over all all columns
                                                if (key === "key") {
                                                    continue
                                                }
                                                let value: string = String(e[key]);
                                                if (value.includes(searchVal)) {
                                                    return true;  // if one col co// ntains searchString -> keep row
                                                }
                                            }
                                            return false; // else -> remove row
                                        }
                                    ));
                                }
                            }
                        }
                    />
                ) : (<></>)
            }
        >
            <Table
                loading={loading}
                dataSource={filteredData}
                columns={columns}
                onChange={onChange}
                {...props.tableProps}
            >
                <Table.Column
                    title=""
                    render={() => (
                        <Button><ArrowRightOutlined/></Button>
                    )
                    }
                />
            </Table>
        </Card>
    );
}