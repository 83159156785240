import * as $ from 'jquery'
import TokenMgr from "./token";


export function send_log(message: String): JQueryPromise<any> {
    let data: any = {
        "msg": message,
    }
    if (TokenMgr.get()) {
        data['extra'] = {"token_content": TokenMgr.get()!.content()}
    }
    return $.ajax({
        // @ts-ignore
        url: new URL("https://analytics.aiandor.de/api/v1/sink/ui").toString(),
        method: "POST",
        headers: {
            TOKEN: "123",
        },
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json"
    })
}