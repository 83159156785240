import {AnyAction} from "@reduxjs/toolkit";
import {post} from "../core/api";
import TokenMgr from "../core/token";

export interface AuthState {
    isFetching: boolean,
    isAuthenticated: boolean,
    user: any,
}

export enum AuthActionTypes {
    LOGIN = 'LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_ERROR = 'LOGIN_ERROR',
    LOGOUT = 'LOGOUT',
}

export const authActions = {
    login: () => ({'type': AuthActionTypes.LOGIN}),
    loginSuccess: (user: string) => ({'type': AuthActionTypes.LOGIN_SUCCESS, 'payload': user}),
    loginError: () => ({'type': AuthActionTypes.LOGIN_ERROR}),
    logout: () => ({'type': AuthActionTypes.LOGOUT}),
}

// if there is a token assume, we are logged in
const defaultState = {
    isFetching: false,
    isAuthenticated: !!TokenMgr.get(),
    user: (TokenMgr.get() && TokenMgr.get()?.content()['user']) ? TokenMgr.get()?.content()['user'] : null,
};

export function authReducer(state = defaultState, action: AnyAction): AuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
            return {
                ...state,
                isFetching: true
            };
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                user: action.payload,
            };
        case AuthActionTypes.LOGIN_ERROR:
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                user: null,
            };
        default:
            return state;
    }
}

export const loginAsync = (user: string, password: string) => async (dispatch: any) => {
    dispatch(authActions.login());
    try {
        await post("auth/login", {'user': user, 'password': password})
        dispatch(authActions.loginSuccess(user));
    } catch (error) {
        dispatch(authActions.loginError());
    }
};

export const logoutAsync = () => async (dispatch: any) => {
    TokenMgr.del();
    dispatch(authActions.logout());
}