import React, {useEffect, useState} from "react";
import ReactMarkdown from 'react-markdown';
import {Breadcrumb, Card} from "antd";
import {Link} from "react-router-dom";
import '../style/release-info.less';

export default function VersionInfoView(props: any) {
    const [markdown, setMarkdown] = useState("")

    useEffect(() => {
        fetch('ReleaseInfo.md')
            .then(response => response.text())
            .then(text => setMarkdown(text))
    })

    return (
        <>
            <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Release Informationen</Breadcrumb.Item>
            </Breadcrumb>

            <Card title="Release Informationen">
                <ReactMarkdown>{markdown}</ReactMarkdown>
            </Card>
        </>
    );
}
