import {configureStore, Reducer, Store} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {CombinedState} from "./reducers/root-reducer";
import {Action} from "redux";

let store: Store<CombinedState, Action> | null = null;

export function initStore(rootReducer: Reducer) {
    store = configureStore({
        reducer: rootReducer,
        middleware: [thunk],
    })
}

export function getStore(): Store<CombinedState, Action> {
    if (store) {
        return store;
    }
    throw new Error("No store initialized!");
}