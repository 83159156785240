import React from "react";
import {Avatar, Layout, Space, Tooltip} from "antd";
import {PoweroffOutlined, UserOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {logoutAsync} from "../reducers/auth-reducer";
import {CombinedState} from "../reducers/root-reducer";
import TokenTimer from "./token-timer";

export default function Header(props: any) {
    const dispatch = useDispatch()
    let user = useSelector((state: CombinedState) => state.auth.user);

    return (
        <Layout.Header className='header'
                       style={{height: '48px', lineHeight: '48px', padding: 0, borderBottom: '1px solid #f0f0f0'}}>
            <Space align='end' size={18} style={{float: 'right', paddingRight: 18}}>
                <Tooltip
                    overlay='Timer zurücksetzen'>
                    <div className='btn'>
                        <TokenTimer/>
                    </div>
                </Tooltip>

                <div className='btn disabled'><Avatar><UserOutlined/></Avatar>&nbsp;{user}</div>

                <div
                    className='btn'
                    onClick={() => dispatch(logoutAsync())}>
                    <PoweroffOutlined/>&nbsp;Abmelden
                </div>
            </Space>
        </Layout.Header>
    );

}

