import {Result} from "antd";
import {ToolTwoTone} from "@ant-design/icons";
import React from "react";

export default function StillWorking(props:any){
    return(
        <Result
            icon={<ToolTwoTone/>}
            title='An diesem Feature wird derzeit noch gearbeitet.'
        />
    );
}