import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import CustomersView from "./views/customers-view";
import CustomerDetailView from "./views/customer-detail-view";
import {useSelector} from "react-redux";
import {CombinedState} from "./reducers/root-reducer";
import ErrorView from "./views/error-view";
import CustomerReportView from "./views/customer-report-view";
import DevicesView from "./views/devices-view";
import DeviceDetailView from "./views/device-detail-view";
import UploadView from "./views/upload-view";
import VersionInfoView from "./views/version-info-view";


export default function Routes(props: any) {
    let isAuthenticated = useSelector((state: CombinedState) => state.auth.isAuthenticated)
    return (
        isAuthenticated ? (
            <Switch>
                {/*Dashboard*/}
                <Route exact path='/'>
                    {/*TODO add Dashborad*/}
                    <Redirect to="/customers"/>
                    {/*<DashboardView/>*/}
                </Route>

                {/*VersionInfo*/}
                <Route exact path='/info'>
                    <VersionInfoView/>
                </Route>

                {/*List of Customers*/}
                <Route exact path='/customers'>
                    <CustomersView/>
                </Route>

                {/*Customer Details*/}
                <Route exact path='/customers/:id'>
                    <CustomerDetailView/>
                </Route>

                {/*Customer Montly Report*/}
                <Route exact path='/customers/:id/report'>
                    <CustomerReportView/>
                </Route>

                {/*List of Devices*/}
                <Route exact path='/devices'>
                    <DevicesView/>
                </Route>

                {/*Device Details*/}
                <Route exact path='/devices/:id'>
                    <DeviceDetailView/>
                </Route>

                {/*Upload for Macros*/}
                <Route exact path='/upload'>
                    <UploadView/>
                </Route>

                {/*Catch unknown routes*/}
                <Route path="*">
                    <ErrorView/>
                </Route>

            </Switch>
        ) : (
            <Route
                path="*"
                render={({location}) => <Redirect
                    to={location.pathname.length > 1 ? `/login?next=${location.pathname}` : '/login'}/>}
            />
        )
    );
}
