import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from "react-router-dom";
import MeProXApp from './meprox-app';
import {getStore, initStore} from "./store";
import {Provider} from "react-redux";
import createRootReducer from "./reducers/root-reducer";

initStore(createRootReducer());

ReactDOM.render(
    <React.StrictMode>
        <Provider store={getStore()}>
            <HashRouter hashType='slash'>
                <MeProXApp/>
            </HashRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

//TODO add loging?