import React, {useState} from "react";
import {Breadcrumb, Space, Tooltip} from "antd";
import {Link} from "react-router-dom";
import ApiTable from "../components/table";
import {InfoCircleOutlined, ReconciliationOutlined, TeamOutlined} from "@ant-design/icons";

export default function CustomersView(props: any) {

    const [tableHeight, setTableHeight] = useState(window.innerHeight - 48 - 34 - 65 - 55 - 24 - 70);

    // resize table on change of windowSize
    window.addEventListener('resize', () =>
        setTableHeight(window.innerHeight - 48 - 34 - 65 - 55 - 24 - 70)
    )

    return (
        <>
            <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Kunden</Breadcrumb.Item>
            </Breadcrumb>

            <ApiTable
                      id="Customer"
                      url="customers"
                      searchable
                      title={<><TeamOutlined/> Kunden</>}
                      tableProps={{
                          scroll: {
                              y: Math.max(tableHeight, 240),
                          },
                          size: "small",
                          pagination: false,
                      }}
                      actions={(text: any, record: any) => (
                          <Space size={12}>
                              <Tooltip
                                  overlay='Kundendetails'>
                                  <Link to={"customers/" + record.key}>
                                      <InfoCircleOutlined/>
                                  </Link>
                              </Tooltip>
                              <Tooltip
                                  overlay='Monatsbericht'>
                                  <Link to={"customers/" + record.key + "/report"}>
                                      <ReconciliationOutlined/>
                                  </Link>
                              </Tooltip>
                          </Space>

                      )}
            />
        </>
    );
}