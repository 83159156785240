class Token {
    private token: string;

    constructor(token: string) {
        this.token = token;
    }

    public remainingStr(): string {
        try {
            return new Date(
                Math.max(
                    this.remainingMs(),
                    0 // don't drop below 0
                )
            ).toISOString().substr(14, 5);  // format to MM:SS
        } catch (e) {
            return "??:??";
        }
    }

    public isExpired(): boolean {
        return this.remainingMs() <= 0;
    }

    public str(): string {
        return this.token;
    }

    public exp(): Date {
        try {
            return new Date(this.content()['exp'] * 1000);
        } catch (e) {
            return new Date();
        }

    }

    public iat(): Date {
        try {
            return new Date(this.content()['iat'] * 1000);
        } catch (e) {
            return new Date();
        }

    }

    public content(): any {
        try {
            return JSON.parse(
                atob(  // base64decode
                    this.token.split('.')[1]  // get payload part
                )
            );
        } catch (e) {
            return {}
        }
    }

     public age(): number {
        return new Date().getTime() - this.iat().getTime()
    }

    private remainingMs(): number {
        return this.exp().getTime() - new Date().getTime()
    }
}

export default class TokenMgr {
    static get(): Token | undefined {
        let token_str = window.localStorage.token;
        return token_str && token_str !== "undefined" ? new Token(token_str) : undefined;  // if token exists return token object, else undefined
    }

    static set(token_str: string): void {
        window.localStorage.token = token_str;
    }

    static del(): void {
        window.localStorage.token = undefined;
        window.localStorage.removeItem("token");
    }
}
