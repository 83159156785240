import React from "react";
import {Layout, Menu} from "antd";
import {Link} from "react-router-dom";
import {DashboardOutlined, HomeOutlined, ImportOutlined, TagsOutlined, TeamOutlined} from "@ant-design/icons";

export default function Sider(props: any) {
    return (
        <Layout.Sider
            collapsedWidth={48}
            collapsible>
            <Link to='/'>
                <div className='logo ant-menu-item'>
                    <DashboardOutlined style={{fontSize: '24px', color: '#fff'}}/>
                    <h1>MPExpert</h1>
                </div>
            </Link>
            <Menu theme='dark' selectable={false}>
                <Menu.Item
                    icon={<HomeOutlined/>}
                    disabled // TODO enable once available
                >
                    <Link to='/'>Home</Link>
                </Menu.Item>
                <Menu.Item icon={<TeamOutlined/>}>
                    <Link to='/customers'>Kunden</Link>
                </Menu.Item>
                <Menu.Item icon={<TagsOutlined/>}>
                    <Link to='/devices'>Geräte</Link>
                </Menu.Item>
                <Menu.Item
                    icon={<ImportOutlined/>}
                >
                    <Link to='/upload'>Upload</Link>
                </Menu.Item>
            </Menu>
        </Layout.Sider>
    );
}
