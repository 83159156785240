import React from 'react';
import Routes from "./routes";
import Header from "./components/header";
import Sider from './components/sider';
import Footer from "./components/footer";
import {ConfigProvider, Layout} from "antd";
import deDE from 'antd/lib/locale/de_DE';

import './style/meprox.less'
import {Route, Switch} from "react-router-dom";
import LoginView from "./views/login-view";
import {get} from "./core/api";
import TokenMgr from "./core/token";


export default function MeproxApp(props: any) {
    window.addEventListener('click', (e) => {
        if (TokenMgr.get()) {
            // token has no age or ist older than 5 minutes -> renew on click
            if (TokenMgr.get()!.age() && TokenMgr.get()!.age() < 30000) {
            } else {
                get("auth/renew");
            }
        }
        e.stopImmediatePropagation();
    })

    return (
        <ConfigProvider locale={deDE}>
            <Switch>
                {/*Login Page*/}
                <Route exact path='/login'>
                    <LoginView/>
                </Route>

                {/*Internal pages*/}
                <Route path="*">
                    <Layout style={{height: '100vh'}}>
                        <Sider/>
                        <Layout>
                            <Header/>
                            <div className='scrollable'>
                                <Layout.Content>
                                    <Routes/>
                                </Layout.Content>
                                <Footer/>
                            </div>
                        </Layout>
                    </Layout>
                </Route>

            </Switch>
        </ConfigProvider>
    )
}
