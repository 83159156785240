import React from "react";
import {Redirect, withRouter} from 'react-router-dom'
import {Button, Form, Input} from "antd";
import {loginAsync} from "../reducers/auth-reducer";
import {useDispatch, useSelector} from "react-redux";
import {CombinedState} from "../reducers/root-reducer";
import "../style/login.less";
import {DashboardOutlined} from "@ant-design/icons";


function LoginView(props: any) {
    let isFetching = useSelector((state: CombinedState) => state.auth.isFetching)
    let isAuthenticated = useSelector((state: CombinedState) => state.auth.isAuthenticated)
    let next = new URLSearchParams(props.location.search).get("next");
    const dispatch = useDispatch();

    return (
        <div className='bg-container'>

            {isAuthenticated ? (<Redirect to={next ? next : "/"}/>) : (<div/>)}

            <div className='login-container rounded'>
                <span style={{textAlign: 'center'}}>
                    <h1><DashboardOutlined/> MPExpert</h1>
                </span>

                <form key='auth' id='login' name='login' onSubmit={(e: any) => {
                    e.preventDefault();
                    const data = new FormData(e.target);
                    dispatch(loginAsync(data.get('user') as string, data.get('password') as string));
                }}>
                    <Form.Item>
                        <Input key='user'
                               id='user'
                               name='user'
                               placeholder='Benutzer'
                               required
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input.Password id='password' name='password' placeholder='Passwort' required/>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isFetching}
                            block
                        >
                            {isFetching ? "" : "Log In"}
                        </Button>
                    </Form.Item>
                </form>
            </div>
        </div>
    );
}


export default withRouter(LoginView)