import React, {useState} from "react";
import Dragger from "antd/lib/upload/Dragger";
import {macroRequest} from "../core/api"
import {Breadcrumb, Card, Col, Form, Select} from "antd";
import {ImportOutlined, InboxOutlined, StopOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

export default function UploadView(props: any) {
    const draggerProps = {
        multiple: true,
        customRequest: macroRequest,
    };

    const fileTypes: { [key: string]: string[] } = {
        "TeamPlay": [".xlsx"],
        "Domako": [".csv"]
    }

    const [system, setSystem] = useState("TeamPlay");
    const [type, setType] = useState("");


    return (
        <>
            <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Upload</Breadcrumb.Item>
            </Breadcrumb>

            <Card title={<><ImportOutlined/> Upload</>}>
                <Form layout="horizontal" labelCol={{span: 6}} wrapperCol={{span: 12}}>
                    <Form.Item label="System">
                        <Select id="system" defaultValue="TeamPlay" onChange={(v) => setSystem(v)}>
                            <Select.Option value="Domako">Domako</Select.Option>
                            <Select.Option value="TeamPlay">TeamPlay</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Gerätetyp">
                        <Select id="typ" defaultValue="Bitte wählen..." onChange={(v) => setType(v)}>
                            <Select.Option value="CT">CT</Select.Option>
                            <Select.Option value="MG">MG</Select.Option>
                            <Select.Option value="XR">XR</Select.Option>
                            <Select.Option value="NM" disabled>NM</Select.Option>
                            <Select.Option value="PET" disabled>PET</Select.Option>
                            <Select.Option value="RF" disabled>RF</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{span: 24}} style={{marginBottom: 0}}>
                        <Col span={12} offset={6}>
                            <Dragger {...draggerProps}
                                     accept={fileTypes[system].join()}
                                     disabled={type === ""}
                                     action={"?system=" + system + "&type=" + type}
                                     style={{color: 'gray'}}
                            >
                                {(type === "") ? (<>
                                    <p className="ant-upload-drag-icon">
                                        <StopOutlined style={{color: 'gray'}}/>
                                    </p>
                                    <p className="ant-upload-text">Bitte einen Gerätetyp wählen.</p>
                                    <p className="ant-upload-hint"></p>
                                </>) : (<>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">
                                        Zum Hochladen klicken oder eine Datei in diesen Bereich ziehen.
                                    </p>
                                    <p className="ant-upload-hint">
                                        Für {system} werden die folgenden Dateitypen
                                        unterstützt: {fileTypes[system].join(", ")}
                                    </p>
                                </>)}
                            </Dragger>
                        </Col>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
}
