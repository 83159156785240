import React from "react";
import {Button, Result} from "antd";
import {Link} from "react-router-dom";

export default function ErrorView(props: any) {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Entschuldigung, diese Seite gibt es leider nicht."
            extra={<Link to='/'><Button type="primary">Zurück zum Start</Button></Link>}
        />
    );
}
