import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Card, Collapse, DatePicker, message, notification, Skeleton} from "antd";
import {Link, useParams} from "react-router-dom";
import moment from 'moment';
import {get} from "../core/api";
import locale from 'antd/es/date-picker/locale/de_DE';
import {CopyOutlined} from "@ant-design/icons";
import {dict} from "../utils/types";

export default function CustomerReportView(props: any) {
    const getLastMonth = () => {
        let date = new Date()  // today
        date.setDate(0)  // set to last day of previous month
        return moment(date.toISOString().slice(0, 7), "YYYY-MM")  // only use month and year
    }

    const params: { id: string } = useParams();

    const [customerData, setCustomerData] = useState({
        "data": {
            officename: <Skeleton.Button active size='small' style={{width: 100, height: 14, marginTop: 5}}/>,
        },
        "names": {}
    })
    const [date, setDate] = useState(getLastMonth())
    const [reportData, setReportData] = useState(dict);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        get("customers/" + params.id).done((result) => setCustomerData(result))
    }, [params.id])

    useEffect(() => {
        let month = date.format("MM");
        let year = date.format("YYYY");
        setLoading(true);
        get("customers/" + params.id + "/report?year=" + year + "&month=" + month)
            .done((result) => {
                    setReportData(result.report)
                }
            )
            .always(() => setLoading(false));
    }, [params.id, date])

    const datePicker = <DatePicker picker="month"
                                   format="MMM YYYY"
                                   locale={locale}
                                   allowClear={false}
                                   defaultValue={getLastMonth()}
                                   onChange={(value) => setDate(value!)}/>

    const copy2clipboard = (text: string) => {
        return <Button
            disabled={text === ""}
            icon={<CopyOutlined/>}
            onClick={
                event => {
                    event.stopPropagation();
                    try {
                        navigator.clipboard.writeText(text);
                        message.success({
                            content: "Kopieren abgeschlossen.",
                            duration: 1,
                        });
                    } catch (e) {  // catch errors, e.g. if we dont have access to the clipboard
                        notification.error({
                            message: "Kopieren fehlgeschlagen!",
                            description: "Bitte überprüfen Sie die Zugriffsrechte des Browsers auf die Zwischenablage.",
                        });
                    }
                }
            }>
            in Zwischenablage kopieren
        </Button>
    };

    const report2HTML = (reportContent: string) => (
        <>{reportContent.split("\n").map((part: string) => <>{part}<br/></>)}</>
    )

    return (
        <>
            <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/customers">Kunden</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link
                    to={"/customers/" + params.id}>{customerData.data.officename}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Monatsbericht</Breadcrumb.Item>
            </Breadcrumb>


            <Card
                title={<h1>Monatsbericht: {customerData.data.officename}</h1>}
                extra={datePicker}
            >
                <Collapse defaultActiveKey={0}>
                    {
                        loading ? (
                            <Collapse.Panel key={0}
                                            header={""}
                                            extra={copy2clipboard("")}>
                                <Skeleton active/>
                            </Collapse.Panel>
                        ) : (
                            Object.keys(reportData).map(
                                (contactName: string, index: number) => {
                                    let contactContent: string = reportData[contactName];
                                    return <Collapse.Panel key={index} header={contactName}
                                                           extra={copy2clipboard(contactContent)}>
                                        {report2HTML(contactContent)}
                                    </Collapse.Panel>
                                }
                            )
                        )
                    }
                </Collapse>
            </Card>
        </>
    );
}