import React, {useEffect, useState} from "react";
import TokenMgr from "../core/token";
import {ReloadOutlined} from "@ant-design/icons";
import {get} from "../core/api";
import timeout from "../utils/timeout";
import {logoutAsync} from "../reducers/auth-reducer";
import {useDispatch} from "react-redux";

export default function TokenTimer() {
    const [spin, setSpin] = useState(false)
    const [timer, setTimer] = useState("--:--");
    const dispatch = useDispatch()

    function tick() {
        let token = TokenMgr.get()
        if (token) {
            let remainig = token.remainingStr();
            setTimer(remainig ? remainig : "");
            if (token.isExpired()) {
                dispatch(logoutAsync());
            }
        }
    }

    useEffect(() => {
        var interval = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(interval);
        };
    });

    return <div onClick={
        () => {
            setSpin(true);
            get("auth/renew")
                .done(() => timeout(1000).then(() => setSpin(false)))  // spin 1 sec
                .fail(() => setSpin(false) //stop immediately
                )
        }
    }><ReloadOutlined spin={spin}/>&nbsp;{timer}</div>;
}