import React, {useState} from "react";
import {Breadcrumb, Space, Tooltip} from "antd";
import {Link} from "react-router-dom";
import ApiTable from "../components/table";
import {InfoCircleOutlined, TagsOutlined} from "@ant-design/icons";

export default function DevicesView(props: any) {

    const [tableHeight, setTableHeight] = useState(window.innerHeight - 48 - 34 - 65 - 39 - 24 - 70);

    // resize table on change of windowSize
    window.addEventListener('resize', () =>
        setTableHeight(window.innerHeight - 48 - 34 - 64 - 39 - 24 - 70)
    )

    return (
        <>
            <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Geräte</Breadcrumb.Item>
            </Breadcrumb>

            <ApiTable id="Devices"
                      url="devices"
                      searchable
                      title={<><TagsOutlined/> Geräte</>}
                      tableProps={{
                          scroll: {
                              y: Math.max(tableHeight, 240),
                          },
                          size: "small",
                          pagination: false,
                      }}
                      actions={(text: any, record: any) => (
                          <Space size={12}>
                              <Tooltip
                                  overlay='Gerätedetails'>
                                  <Link to={"devices/" + record.key}>
                                      <InfoCircleOutlined/>
                                  </Link>
                              </Tooltip>
                          </Space>
                      )}
            />
        </>
    );
}