import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Card, Descriptions, PageHeader, Skeleton, Tabs, Tooltip} from "antd";
import {Link, useParams} from "react-router-dom";
import ApiTable from "../components/table";
import {
    CodeOutlined,
    DashboardOutlined,
    EnvironmentOutlined,
    InfoCircleOutlined,
    MailOutlined,
    PhoneOutlined,
    ReconciliationOutlined,
    TagOutlined,
    TagsOutlined,
    UserOutlined
} from "@ant-design/icons";
import StillWorking from "../components/still-working";
import {get} from "../core/api";

const placeholder = <Skeleton.Button active size='small' style={{width: 100, height: 14, marginTop: 5}}/>

const defaultCustomerData = {
    "data": {
        officename: placeholder,
        contactpersonit: placeholder,
        contactpersonitphonenumber: placeholder,
        contactpersonitmailaddress: placeholder,
        billingaddress: placeholder,
        software: placeholder,
        assignedto: placeholder,
    },
    "names": {}
}

export default function CustomerDetailView(props: any) {
    const params: { id: string } = useParams();
    const [customerData, setCustomerData] = useState(defaultCustomerData)

    useEffect(() => {
        get("customers/" + params.id).done((result) => setCustomerData(result))
    }, [params.id])

    return (
        <>
            <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/customers">Kunden</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{customerData.data.officename}</Breadcrumb.Item>
            </Breadcrumb>

            <PageHeader
                ghost={false}
                title={customerData.data.officename}
                extra={[
                    <Link to={"/customers/" + params.id + "/report"}><Button type="ghost" icon={
                        <ReconciliationOutlined/>}>Monatsbericht</Button></Link>
                ]}
            >
                <Descriptions size="small" colon={false}>
                    {true || customerData.data.officename ? ( // 'true ||' -> show empty cells anyways
                        <Descriptions.Item
                            label={<Tooltip overlay={"Kundenname"}>
                                <InfoCircleOutlined style={{lineHeight: '22px', alignSelf: 'center'}}/>
                            </Tooltip>}>
                            {customerData.data.officename}</Descriptions.Item>
                    ) : (<></>)}

                    {true || customerData.data.contactpersonit ? (
                        <Descriptions.Item label={
                            <Tooltip overlay={"Ansprechpartner"}>
                                <UserOutlined style={{lineHeight: '22px', alignSelf: 'center'}}/>
                            </Tooltip>}>
                            {customerData.data.contactpersonit}</Descriptions.Item>) : (<></>)}

                    {true || customerData.data.contactpersonitphonenumber ? (
                        <Descriptions.Item label={
                            <Tooltip overlay={"Telefonnummer des Ansprechpartners"}>
                                <PhoneOutlined style={{lineHeight: '22px', alignSelf: 'center'}}/>
                            </Tooltip>}>
                            {customerData.data.contactpersonitphonenumber}</Descriptions.Item>) : (<></>)}

                    {true || customerData.data.contactpersonitmailaddress ? (
                        <Descriptions.Item label={
                            <Tooltip overlay={"Email des Ansprechpartners"}>
                                <MailOutlined style={{lineHeight: '22px', alignSelf: 'center'}}/>
                            </Tooltip>}>
                            {customerData.data.contactpersonitmailaddress}</Descriptions.Item>) : (<></>)}

                    {true || customerData.data.billingaddress ? (
                        <Descriptions.Item
                            label={<Tooltip overlay={"Rechnungsadresse"}>
                                <EnvironmentOutlined style={{lineHeight: '22px', alignSelf: 'center'}}/>
                            </Tooltip>}>
                            {customerData.data.billingaddress}</Descriptions.Item>) : (<></>)}

                    {true || customerData.data.software ? (
                        <Descriptions.Item label={<Tooltip overlay={"Software"}>
                            <CodeOutlined style={{lineHeight: '22px', alignSelf: 'center'}}/>
                        </Tooltip>}>
                            {customerData.data.software}</Descriptions.Item>) : (<></>)}

                    {true || customerData.data.assignedto ? (
                        <Descriptions.Item
                            label={
                                <Tooltip overlay={"Zugewiesener MPE"}>
                                    <TagOutlined style={{lineHeight: '22px', alignSelf: 'center'}}/>
                                </Tooltip>}>
                            {customerData.data.assignedto}</Descriptions.Item>) : (<></>)}

                </Descriptions>
            </PageHeader>

            <Tabs defaultActiveKey="2" style={{minHeight: '100%'}}
                  animated
                  tabBarStyle={{paddingLeft: '12px', marginBottom: 12}}>
                <Tabs.TabPane
                    tab={<span><DashboardOutlined/>Übersicht</span>}
                    key="1"
                    disabled // TODO enable once available
                >
                    <DashboardTab/>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span><TagsOutlined/>Geräte</span>}
                    key="2">
                    <DevicesTab/>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span><InfoCircleOutlined/>Details</span>}
                    key="3">
                    <DetailsTab customerData={customerData}/>
                </Tabs.TabPane>
            </Tabs>
        </>
    );
}

function DashboardTab(props: any) {
    return (
        <StillWorking/>
    );
}

function DetailsTab(props: any) {
    return (
        <Card
            title={<><InfoCircleOutlined/> Details</>}
            headStyle={{backgroundColor: 'rgb(250, 250, 250)'}}
            bodyStyle={{padding: 0}}
        >

            <Descriptions
                bordered
                size='small'
                column={
                    1
                    //{xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1}
                }
                contentStyle={
                    {paddingLeft: 12, paddingRight: 12}
                }
            >
                {Object.keys(props.customerData.data).map(
                    (key) => {
                        if (key === "persistence_object_identifier") {
                            return <></>
                        }
                        let label = key;
                        if (key in props.customerData.names) {
                            label = props.customerData.names[key];
                        }
                        if (!props.customerData.data[key] || props.customerData.data[key] === "") {
                            return (<></>);
                        }
                        return (<Descriptions.Item label={label}> {props.customerData.data[key]} </Descriptions.Item>);
                    }
                )}
            </Descriptions>
        </Card>
    );
}

function DevicesTab(props: any) {
    const params: { id: string } = useParams();
    return (
        <ApiTable
            id="CustomerDevices"
            url={"devices?customer=" + params.id}
            searchable
            title={<><TagsOutlined/> Geräte</>}
            tableProps={{
                size: "small",
                pagination: false,
            }}
        />
    );
}