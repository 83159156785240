import React from "react";
import {Layout, Space, Tooltip} from "antd";
import {BugOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

export default function Footer(props: any) {
    return (
        <Layout.Footer style={{padding: 24}}>
            © 2021 Jenke & Rauschert GbR
            <span style={{float: "right"}}>
                <Space>
                    <Tooltip
                        placement="topRight"
                        overlay='Release Informationen ansehen'
                        arrowPointAtCenter>
                        <Link to='/info'>
                            <InfoCircleOutlined
                                style={{color: "gray", fontSize: '18px'}}/>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        placement="topRight"
                        overlay='Einen Fehler melden'
                        arrowPointAtCenter>
                        <a href='mailto:info@aiandor.de'>
                            <BugOutlined
                                style={{color: "gray", fontSize: '18px'}}/>
                        </a>
                    </Tooltip>
                </Space>
            </span>
        </Layout.Footer>
    );
}