import * as $ from 'jquery'
import TokenMgr from "./token";
import {getStore} from "../store";
import {logoutAsync} from "../reducers/auth-reducer";
import {message, notification} from "antd";
import {v4 as get_uuid} from 'uuid';
import {send_log} from "./logging";


import {ArgsProps as MessageArgsProps} from "antd/lib/message";
import {ArgsProps as NotificationArgsProps} from "antd/lib/notification";

function handleResponse(response: any) {
    // handle token
    if (response.token) {
        TokenMgr.set(response.token);
    }

    // handle popups
    if (response.popup) {
        if (response.popup.method === "notification") {
            let uuid = get_uuid();
            let config: NotificationArgsProps = {
                message: response.popup.message,
                description: response.popup.description,
                type: response.popup.type,
                key: uuid,
                onClick: () => {
                    notification.close(uuid)
                }
            }

            if (response.popup.duration) {
                config['duration'] = response.popup.duration;
            }
            notification.open(config);
        }

        if (response.popup.method === "message") {
            let config: MessageArgsProps = {
                content: response.popup.content,
                duration: 3,
                type: response.popup.type,
            }
            if (response.popup.duration) {
                // @ts-ignore
                config['duration'] = response.popup.duration;
            }
            message.open(config);
        }
    }

    // hanlde actions
    if (response.action) {
        switch (response.action) {
            case "DeleteToken":
                // @ts-ignore
                getStore().dispatch(logoutAsync()); // delete invalid token and logout
        }
    }
}

function request(uri: string, method: string, data?: { [key: string]: string }): JQueryPromise<any> {
    return $.ajax({
        // @ts-ignore
        url: new URL(uri, window.__API_URL__).toString(),
        method: method,
        headers: {
            cookies: "token=" + TokenMgr.get()?.str(),
        },
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json"
    })
        .fail(
            (jqXHR, status, error) => {
                try {
                    if (status === "error"
                        && (!jqXHR.responseJSON || jqXHR.responseJSON.status !== "error")
                    ) {
                        notification.error({
                            message: "Fehler",
                            description: (error !== "") ? (error) : ("Leider ist etwas schief gelaufen. Für weitere Informationen bitte die Konsole öffnen.")
                        });
                        send_log(jqXHR.status + " Error during call to " + uri + ": " + error);
                    }
                } catch (e) {
                    console.error(e);
                    // @ts-ignore
                    send_log("FatalError during call to " + uri + ": " + (e as Exception).Message)
                }
            }
        )
        .then(
            (response) => { // handle response content
                handleResponse(response);
                return response;
            },
            (jqXHR) => { // handle response content from jqXHR
                handleResponse(jqXHR.responseJSON);
                return jqXHR;
            }
        )
}

function get(uri: string): JQueryPromise<any> {
    return request(uri, "GET");
}

function post(uri: string, data: { [key: string]: string }): JQueryPromise<any> {
    return request(uri, "POST", data);
}

function patch(uri: string, data: { [key: string]: string }): JQueryPromise<any> {
    return request(uri, "PATCH", data);
}

function del(uri: string): JQueryPromise<any> {
    return request(uri, "DELETE");
}

const macroRequest = (options: any) => {
    let file = options.file;
    var data = new FormData();
    data.append("file", file);
    var xhr = new XMLHttpRequest();
    xhr.open(
        'POST',
        // @ts-ignore
        new URL("macros/format" + options.action, window.__API_URL__).toString(),
    );
    xhr.setRequestHeader("cookies", "token=" + TokenMgr.get()?.str(),)
    xhr.responseType = 'blob';
    xhr.onload = (e) => {
        var fileName = 'Formated_' + file.name.replace(/\.[^/.]+$/, ".xlsx");
        if (xhr.status <= 300) {
            if (xhr.status === 207) {
                let uuid = get_uuid();
                notification.warning({
                    message: " Verarbeitungsfehler",
                    description: "Bei der Verarbeitung der Excel-Datei wurden einzelne Berechnungen aufgrund von Fehlern nicht durchgeführt. Bitte die Korrektheit der Datei '" + fileName + "' überprüfen!",
                    key: uuid,
                    onClick: () => {
                        notification.close(uuid)
                    },
                    duration: 20,
                });
            }

            var link = document.createElement("a");
            let url = window.URL.createObjectURL(xhr.response);
            link.href = url;
            link.download = fileName;
            link.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                options.onSuccess(xhr);
            }, 100)
        } else {
            let uuid = get_uuid();
            notification.error({
                message: " Verarbeitungsfehler",
                description: "Bei der Verarbeitung der Excel-Datei ist leider etwas schief gelaufen.",
                key: uuid,
                onClick: () => {
                    notification.close(uuid)
                },
                duration: 5,
            });
            options.onError("Verarbeitungsfehler", xhr);
            send_log(xhr.status + " Error during call to macros/format" + options.action);
        }
    }
    xhr.send(data);
}

export {get, post, patch, del, macroRequest}
