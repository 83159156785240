import React from "react";
import {Breadcrumb, Result} from "antd";
import {Link} from "react-router-dom";
import StillWorking from '../components/still-working'


export default function DeviceDetailView(props: any) {
    return (
        <>
            <Breadcrumb style={{paddingTop: 6, paddingBottom: 6}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/devices">Geräte</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Gerät ABC</Breadcrumb.Item>
            </Breadcrumb>

           <StillWorking/>
        </>
    );
}